import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46a660b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "segmented-controller load-hidden",
    style: _normalizeStyle(_ctx.cssVariables)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.items, (item, index) => {
      return (_openBlock(), _createElementBlock("button", {
        ref_for: true,
        ref: item.key,
        key: item.key,
        class: _normalizeClass([_ctx.store.activeIndex == index ? 'active' : 'inactive']),
        onClick: ($event: any) => (_ctx.changeIndex(index, $event))
      }, _toDisplayString(item.title), 11, _hoisted_1))
    }), 128))
  ], 4))
}