import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cd904f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolio load-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SegmentedController = _resolveComponent("SegmentedController")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_Skills = _resolveComponent("Skills")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SegmentedController),
    (_ctx.store.items[_ctx.store.activeIndex].key == 'projects')
      ? (_openBlock(), _createBlock(_component_Projects, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.store.items[_ctx.store.activeIndex].key == 'skills')
      ? (_openBlock(), _createBlock(_component_Skills, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}