
import { Options, Vue } from "vue-class-component";
import { store } from "@/components/Portfolio/store";
import SegmentedController from "@/components/SegmentedController/SegmentedController.vue";
import Projects from "@/components/Projects/Projects.vue";
import Skills from "@/components/Skills/Skills.vue";
import ScrollReveal from "scrollreveal";

@Options({
  components: {
    SegmentedController,
    Projects,
    Skills,
  },
  data() {
    return {
      store,
    };
  },
  mounted() {
    ScrollReveal().reveal(".portfolio", {
      origin: "bottom",
      delay: 1200,
      duration: 1400,
      distance: "60px",
    });
  },
  created() {
    if (this.$route.query && this.$route.query.view) {
      const activeIndex = store.items.findIndex(
        (item) => item.key == this.$route.query.view
      );
      store.setActiveIndex(Math.max(activeIndex, 0));
    }
  },
})
export default class Portfolio extends Vue {}
