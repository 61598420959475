
import { Options, Vue } from "vue-class-component";
import { nextTick } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import validator from "validator";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

const HTTP_STATUS_OK = 200;

const successMessage = {
  type: "success",
  icon: "img/96245-success.json",
  size: "300",
  title: "Message Sent!",
  subtitle: "You can expect a response within a few hours.",
};

const errorMessage = {
  type: "error",
  icon: "img/90569-error.json",
  size: "200",
  title: "Something went wrong...",
  subtitle: "Please wait a few minutes and try again.",
};

const loadingMessage = {
  type: "loading",
  icon: "img/98891-insider-loading.json",
  size: "200",
  title: "",
  subtitle: "",
};

@Options({
  components: { VueRecaptcha, LottieAnimation },
  data() {
    return {
      showMail: false,
      captchaResponse: undefined,
      from: "",
      body: "",
      maxMessageBody: 400,
      result: undefined,
    };
  },
  methods: {
    show: function () {
      this.showMail = true;
      nextTick(() => {
        this.$refs.from.focus();
      });
    },
    clear: function () {
      this.from = "";
      this.body = "";
      this.captchaResponse = undefined;
    },
    hide: function () {
      this.showMail = false;
      this.captchaResponse = undefined;
      this.result = undefined;
    },
    challengeCompleted: function (r: string) {
      this.captchaResponse = r;
    },
    challengeExpired: function () {
      this.captchaResponse = undefined;
    },
    challengeError: function () {
      this.captchaResponse = undefined;
    },
    isComplete: function () {
      if (
        validator.isEmail(this.from) &&
        this.body.length > 0 &&
        this.captchaResponse
      ) {
        return true;
      }
      return false;
    },
    submit: function () {
      if (!this.isComplete()) {
        return;
      }

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          from: this.from,
          body: this.body,
          "g-recaptcha-response": this.captchaResponse,
        }),
      };

      // Add loading window to prevent double sending
      this.result = loadingMessage;
      fetch(
        "https://lctkpkleleciahfsicdyydqppfrcp.bond/rest/v1/email",
        requestOptions
      )
        .then((response) => {
          this.clear();

          if (response.status == HTTP_STATUS_OK) {
            this.result = successMessage;
          } else {
            this.result = errorMessage;
          }
        })
        .catch(() => {
          this.clear();
          this.result = errorMessage;
        });
    },
  },
})
export default class Mail extends Vue {}
