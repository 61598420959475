import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTheme && _ctx.toggleTheme(...args))),
    ref: "changeTheme",
    class: "change-theme load-hidden"
  }, null, 512))
}