
import { Options, Vue } from "vue-class-component";
import ScrollReveal from "scrollreveal";

@Options({
  mounted() {
    ScrollReveal().reveal(".skills", {
      origin: "bottom",
      delay: 100,
      duration: 800,
      distance: "60px",
    });
  },
})
export default class Skills extends Vue {}
