
import { Options, Vue } from "vue-class-component";

@Options({
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    if (initUserTheme == "light-theme") {
      this.$refs.changeTheme.classList.add("fa-moon");
      this.$refs.changeTheme.classList.add("fa-regular");
    } else {
      this.$refs.changeTheme.classList.add("fa-sun-bright");
      this.$refs.changeTheme.classList.add("fa-custom");
    }
    this.setTheme(initUserTheme);
  },

  data() {
    return {
      userTheme: "light-theme",
    };
  },
  methods: {
    toggleTheme(e: any) {
      const activeTheme = localStorage.getItem("user-theme");
      e.target.classList.toggle("fa-moon");
      e.target.classList.toggle("fa-regular");
      e.target.classList.toggle("fa-sun-bright");
      e.target.classList.toggle("fa-custom");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme: string) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
})
export default class ThemeButton extends Vue {}
