import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Profile = _resolveComponent("Profile")!
  const _component_Portfolio = _resolveComponent("Portfolio")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Container, null, {
      default: _withCtx(() => [
        _createVNode(_component_Profile),
        _createVNode(_component_Portfolio),
        _createVNode(_component_Footer)
      ]),
      _: 1
    })
  ]))
}