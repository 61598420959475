import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5236c47c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "projects load-hidden grid" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["srcset"]
const _hoisted_4 = ["srcset"]
const _hoisted_5 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
      return (_openBlock(), _createElementBlock("article", {
        key: project.image,
        class: _normalizeClass([{ soon: project.soon, new: project.new }]),
        ref_for: true,
        ref: "projects"
      }, [
        _createElementVNode("a", {
          href: project.href,
          target: "_blank"
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["external fa-solid fa-arrow-up-right-from-square", { light: project.light }])
          }, null, 2),
          _createElementVNode("picture", null, [
            _createElementVNode("source", {
              srcset: require(`../../assets/img/${project.image}.webp`),
              type: "image/webp"
            }, null, 8, _hoisted_3),
            _createElementVNode("source", {
              srcset: require(`../../assets/img/${project.image}.jpg`),
              type: "image/jpeg"
            }, null, 8, _hoisted_4),
            _createElementVNode("img", {
              width: "330px",
              height: "225px",
              src: require(`../../assets/img/${project.image}.jpg`),
              alt: project.image_alt
            }, null, 8, _hoisted_5)
          ])
        ], 8, _hoisted_2)
      ], 2))
    }), 128))
  ]))
}