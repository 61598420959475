
import { Options, Vue } from "vue-class-component";
import { store } from "@/components/Portfolio/store";

@Options({
  data() {
    return {
      xOffset: 6,
      store,
    };
  },
  methods: {
    changeIndex(index: number, e: any) {
      store.setActiveIndex(index);
      this.xOffset = e.target.offsetLeft;
      this.$router.push({ query: { view: store.items[index].key } });
    },
    updateOffset() {
      const activeButton = this.$refs[store.items[store.activeIndex].key];
      if (activeButton && activeButton[0]) {
        this.xOffset = activeButton[0].offsetLeft;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateOffset);
    this.updateOffset();
  },
  unmounted() {
    window.removeEventListener("resize", this.updateOffset);
  },
  computed: {
    cssVariables() {
      return {
        "--highlight-x-pos": this.xOffset + "px",
        "--segments-size": Math.floor(100 / store.items.length) + "%",
      };
    },
  },
})
export default class SegmentedController extends Vue {}
