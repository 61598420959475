
import { Options, Vue } from "vue-class-component";
import Container from "@/components/Container/Container.vue";
import Profile from "@/components/Profile/Profile.vue";
import Portfolio from "@/components/Portfolio/Portfolio.vue";
import Footer from "@/components/Footer/Footer.vue";
import ScrollReveal from "scrollreveal";

@Options({
  components: {
    Container,
    Profile,
    Portfolio,
    Footer,
  },
  mounted() {
    let msg =
      '%c👋 Hello! Since you\'re here... want to hear a joke? (type "yes")';
    let styles = [
      "font-size: 16px",
      'font-family: "Rubik", sans-serif',
      "background: white",
      "display: inline-block",
      "color: black",
      "padding: 12px 26px",
      "border-radius: 4px;",
    ].join(";");
    console.log(msg, styles);
    const sr = ScrollReveal({
      origin: "top",
      distance: "60px",
      duration: 1400,
      delay: 0,
    });
    sr.reveal(".change-theme", { delay: 300, cleanup: true });
    sr.reveal(".avatar", { delay: 300, cleanup: true });
    sr.reveal(".tooltip", { delay: 400, cleanup: true });
    sr.reveal(".role", { delay: 500, cleanup: true });
    sr.reveal(".github", { delay: 600, cleanup: true });
    sr.reveal(".dribbble", { delay: 700, cleanup: true });
    sr.reveal(".linkedin", { delay: 800, cleanup: true });

    sr.reveal(".stats", { delay: 900, interval: 100, cleanup: true });
    sr.reveal(".buttons", { delay: 1000, cleanup: true });
    sr.reveal(".segmented-controller", {
      delay: 1100,
      cleanup: true,
    });
    sr.reveal(".footer", { delay: 1200, cleanup: true, origin: "bottom" });
  },
})
export default class HomeView extends Vue {}
