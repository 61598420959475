
import { Options, Vue } from "vue-class-component";
import ScrollReveal from "scrollreveal";

@Options({
  data() {
    return {
      projects: [
        {
          image: "joinly",
          image_alt: "Joinly Thumbnail",
          soon: true,
          light: false,
        },
        {
          image: "onlne",
          image_alt: "Onlne Thumbnail",
          soon: true,
          light: false,
        },
        {
          image: "magic-upload",
          image_alt: "Magic Upload Thumbnail",
          href: "https://github.com/mack/magic-upload",
          light: false,
        },
        {
          image: "dalcs",
          image_alt: "@dalcs Thumbnail",
          href: "https://github.com/dalcs",
          light: false,
        },
        {
          image: "avon",
          image_alt: "Avon Thumbnail",
          href: "https://github.com/mack/avon",
          light: false,
        },
        {
          image: "github-pinner",
          image_alt: "GitHub Pinner Thumbnail",
          href: "https://github.com/mack/github-pinner",
          light: true,
        },
        {
          image: "space-invaders",
          image_alt: "Space Invaders Thumbnail",
          href: "https://github.com/mack/space-invaders",
          light: false,
        },
        {
          image: "snack-track",
          image_alt: "Snack Track Thumbnail",
          href: "https://github.com/mack/snack-track",
          light: true,
        },
        {
          image: "dal-schedule-builder",
          image_alt: "Dal Schedule Builder Thumbnail",
          href: "https://github.com/mack/dalhousie-schedule-builder",
          light: true,
        },
      ],
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mouseMove(e: any) {
      const scaler = 7;
      const { offsetX, offsetY, currentTarget } = e;
      const { clientWidth, clientHeight } = currentTarget;
      const xPercent = offsetX / clientWidth;
      const yPercent = offsetY / clientHeight;

      const getRotateAmount = (percent: number) =>
        (percent * scaler - scaler / 2).toFixed(2);
      e.currentTarget.style.transform = `scale(1.03, 1.03) perspective(${clientWidth}px) rotateX(${getRotateAmount(
        yPercent
      )}deg) rotateY(${getRotateAmount(xPercent)}deg) scale3d(1, 1, 1)`;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetStyle(e: any) {
      e.currentTarget.style.transform = `scale(1, 1) perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
    },
  },
  mounted() {
    ScrollReveal().reveal(".projects", {
      origin: "bottom",
      delay: 100,
      duration: 800,
      distance: "60px",
    });

    this.$refs.projects.forEach((project: HTMLElement) => {
      project.addEventListener("mousemove", this.mouseMove);
      project.addEventListener("mouseleave", this.resetStyle);
    });
  },
  destroyed() {
    this.$refs.projects.forEach((project: HTMLElement) => {
      project.removeEventListener("mousemove", this.mouseMove);
      project.removeEventListener("mouseleave", this.resetStyle);
    });
  },
})
export default class Projects extends Vue {}
