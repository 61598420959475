import { reactive } from "vue";

export const store = reactive({
  activeIndex: 0,
  items: [
    { title: "Projects", key: "projects" },
    { title: "Skills", key: "skills" },
  ],
  setActiveIndex(index: number) {
    this.activeIndex = index;
  },
});
