
import { Options, Vue } from "vue-class-component";
import ThemeButton from "@/components/ThemeButton/ThemeButton.vue";
import SocialList from "@/components/SocialList/SocialList.vue";
import Stats from "@/components/Stats/Stats.vue";
import Mail from "@/components/Mail/Mail.vue";

@Options({
  components: {
    SocialList,
    Stats,
    ThemeButton,
    Mail,
  },
})
export default class Profile extends Vue {}
