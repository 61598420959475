<template>
  <router-view />
</template>

<style lang="scss">
@import "@/assets/scss/_global.scss";

#app {
  position: relative;
}
</style>
